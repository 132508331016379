import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getAuthorizationToken();
    // console.log("In AuthInterceptorService");
    // console.log(authToken);
    let hdr = {};
    if (authToken && authToken !== "") {
      // console.log('setting hdr');
      hdr = {
        "x-access-token": authToken ? authToken : "",
      };
    }
    // send cloned request with header to the next handler.
    const authReq = req.clone({ setHeaders: hdr });
    return next.handle(authReq);
  }
}
